import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit {


  constructor (private loaderService: LoaderService) {}

  public loading: Subject<boolean>
  async ngOnInit() {
  this.loading = await this.loaderService.isLoading;
    
}


  


}
