import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private auth: LoginService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.isAuthenticated()) {
      this.auth.logout();
      return false;
    }

    this.auth.authCredentialsRec();
    return true;
  }
}
