import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isNullOrUndefined } from 'is-what';
import { BehaviorSubject, Observable } from 'rxjs';
import { environmentCircuits } from 'src/environments/circuits/environment';
import { Token } from '../model/token';
import { UsuarioToken } from '../model/UsuarioToken';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  public cuser:any; //Feels like gambi
  public loginChange: EventEmitter<any> = new EventEmitter();
  private tokenSubject: BehaviorSubject<Token>;
  private token: Observable<Token>;


  constructor(private router: Router, private http: HttpClient) {
    this.tokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('token')));
    this.token = this.tokenSubject.asObservable();
  }


  getCurrentUserValue(): UsuarioToken {
    const jwtHelperService = new JwtHelperService();

    const token = localStorage.getItem('token');
    let usuarioToken: UsuarioToken;
    if (!isNullOrUndefined(token) && token !== 'null') {
      usuarioToken = jwtHelperService.decodeToken(JSON.parse(token).access_token);
    }
    return usuarioToken;
  }

  login(username: string, password: string): Promise<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');


    return this.http.post<any>(`${environmentCircuits.url}/oauth2/token`, body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }).toPromise();
  }

  refresh(token: string) {
    const body = new HttpParams()
      .set('refresh_token', token)
      .set('grant_type', 'refresh_token');

    return this.http.post<any>(`${environmentCircuits.url}/oauth2/token`, body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }).toPromise().then((res: any) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('token', JSON.stringify(res));
      this.tokenSubject.next(res);
      return res;
    });
  }

  isAuthenticated() {
    const token: Token = JSON.parse(localStorage.getItem('token'));
    if (token != null) {
      const currentTime = new Date().getTime();
      return token.expires_in > currentTime;
    } else {
      return false;
    }
  }

  canAccessOs() {
    let accountUser = this.getCurrentUserValue().account
    if ('04cd33db-de00-4afe-9147-e97784703976' === accountUser || this.isAdmin()) {
      return true
    }
    return false
  }

  isAdmin() {
    const user: UsuarioToken = this.getCurrentUserValue();
    if (!isNullOrUndefined(user)) {
      const roles = user.roles;
      return roles.includes('administrador');
    }
    return false;
  }
  isGestor() {
    const user: UsuarioToken = this.getCurrentUserValue();
    if (!isNullOrUndefined(user)) {
      const roles = user.roles;
      return roles.includes('gestor');
    }
    return false;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('filter');
    localStorage.removeItem('token');
    localStorage.removeItem('fcm_token');
    this.tokenSubject.next(null);
    this.router.navigate(['/auth/login']);
  }

  public authCredentialsRec() {
    this.cuser = this.getCurrentUserValue();
    this.loginChange.next(this.getCurrentUserValue());
  }

  getToken() {
    const token = JSON.parse(localStorage.getItem('token')).access_token;

    if (token) {
      return token;
    }
  }

  setToken(token) {
    localStorage.setItem('token', JSON.stringify(token));
    this.tokenSubject.next(token);
  }

  appendTokenHeader() {
    const headers = new HttpHeaders();
    const token = JSON.parse(localStorage.getItem('token')).access_token;

    if (token) {
      return headers.append('Authorization', `Bearer ${token}`);
    }

    return headers;
  }

}
