<!-- <ngx-loading [show]="loading | async" ></ngx-loading> -->

@if (loading | async) {
    <div id="splash-screen" class="splash-screen splash-screen-loader">
        <div class="containerSpinnerAndLogo d-flex flex-column gap-2 justify-content-center align-items-center" style="width: 80px; height: 80px;" >
            <img src="./assets/media/logos/new-logo-short.png" style="width: 60px;" alt="Wisen logotipo" />
            <section class="d-flex flex-column justify-content-center">
                <span class="spinner-border border-3" style="width: 80px; height: 80px;"  role="status">
                    <span class="visually-hidden">Carrendo...</span>
                </span>
            </section>
        </div>
    </div>

}